body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn-link:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #747474;
  border-radius: 10px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* .Sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.Sidebar::-webkit-scrollbar-thumb {
  background: #e68f96;
  border-radius: 10px;
} */

/**sidebar***/

/* .tool {
  border: 0;
  background-color: transparent;
}

.logo {
  width: 102px;
  height: 47px;
  margin: 0 auto;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Sidebar {
  height: 100vh;
  background-color: #e6e6e6;
  width: 14rem;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
  z-index: 3;
}

.Sidebar h6 {
  margin: 10px 0;
  font-size: 13px;
  text-align: center;
}

.Sidebar h5 {
  background-color: #fff;
  padding: 3px 25px;
  width: 100%;
  font-size: 19px;
}

.card {
  background-color: transparent;
  border: 0;
  background: #e6e6e65c;
  border-radius: 0;
}

.card-header {
  padding: 0;
}

.collapsible-link {
  width: 100%;
  position: relative;
  text-align: left;
}

.collapsible-link::before {
  content: "\f107";
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
  display: block;
  font-family: "FontAwesome";
  font-size: 1.1rem;
}

.collapsible-link[aria-expanded="true"]::before {
  content: "\f106";
}

.drops_card {
  background-color: #f6f2f2;
  padding: 10px 10px;
  margin: 20px 0 30px 0;
}

.drops_card h6 {
  background-color: #fff;
  font-size: 15px;
  margin: 0;
  line-height: 23px;
}

.eraserTooltip {
  display: inline-block;
  position: relative;
}

.DateDrop {
  background-color: #e6e6e6;
  position: relative;
  padding: 3px;
}

.tooltiptext {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #000;
  font-size: 12px;
  left: -40px;
  padding: 3px 0;
  position: absolute;
  text-align: center;
  top: -30px;
  visibility: hidden;
  width: 100px;
  z-index: 1;
}

.eraserTooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext:before {
  border: 8px solid #0000;
  border-top-color: #fffefe;
  bottom: -20px;
  content: "";
  display: block;
  height: 22px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.DateDrop i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.DateDrop:hover i {
  opacity: 1;
} */

/**header**/
/* .topHeader {
  display: none;
}

.Header.fixed {
  position: fixed;
  z-index: 99;
  box-shadow: 1px 4px 10px 0px rgb(0 0 0 / 21%);
}

.Header {
  height: 66px;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
}

.navItem {
  overflow-x: auto;
  white-space: nowrap;
  padding: 5px 10px;
}

.Header ul li {
  list-style: none;
}

.Header ul {
  display: flex;
  gap: 15px;
  margin-right: 10px;
}

.Header ul li a {
  background-color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  color: #000;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 42%);
  display: inline-block;
}

.Header ul li a:hover {
  text-decoration: none;
}

.Header ul li a.active {
  background-color: #e6e6e6;
}

.main {
  width: calc(100% - 14rem);
  margin-left: 14rem;
}

.main_content {
  padding: 10px;
  padding-bottom: 20px;
  height: 100%;
} */

/* Header part new */

.headerPart {
  width: calc(100% - 150px);
  margin-left: auto;
  height: auto;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}

.headerPart.sticky {
  position: fixed;
  top: 0;
  left: 150px;
  right: 0;
  width: calc(100% - 150px);
  z-index: 99;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.headerInn {
  display: flex;
  justify-content: space-between;
}

.headerInn i {
  color: #000;
  font-size: 15px;
}

.headerUl {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  padding-bottom: 5px;
  gap: 10px;
}

.headerUl::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.headerUl::-webkit-scrollbar-thumb {
  background-color: #858585;
}

.headerUl li {
  /* min-width: 150px; */
  /* width: auto; */
  background-color: #fff;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(153, 153, 153, 0.3);
}

.headerUl li a {
  display: block;
  color: #000;
  font-size: 13px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

/* .headerUl li a:hover {
  font-size: 12px;
} */

.headerUl li a.active {
  background-color: #cccccc;
}

.headerUl li:hover {
  background-color: #cccccc;
}

.headmenuBtn {
  display: none;
  padding-left: 15px;
}

.btmheader {
  padding-bottom: 3px;
}

.headerPara {
  display: flex;
  justify-content: flex-end;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
}

/* sidebar new */

.sidebarPart {
  width: 150px;
  height: 100vh;
  background-color: #e9e9e9;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  box-shadow: 1px 10px 15px 0px rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
  transition: 0.6s;
}

.Logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.Logo img {
  width: 100%;
  height: 100%;
}

.sideTopHead {
  color: #000;
  font-size: 13px;
  text-align: center;
}

.todayDate {
  padding: 30px 0px;
  text-align: center;
}

.datehead {
  color: #605e5c;
  font-size: 12px;
  margin-bottom: 6px;
}

.datepara {
  color: #000;
  font-size: 11px;
  font-weight: 500;
}

.datefilter {
  width: 100%;
  display: block;
  border: none;
  outline: 0;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
  color: #000;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
}

.datefilter:hover {
  color: #000;
}

.DateDrop {
  background-color: #e6e6e6;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
  width: 130px;
}

.DateDrop p {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.eraserTooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;
  position: absolute;
  top: -30px;
  z-index: 1;
  font-size: 12px;
  left: -40px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.eraserTooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext::before {
  content: "";
  position: absolute;
  bottom: -20px;
  display: block;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
  border-color: #fffefe transparent transparent transparent;
  border-style: solid;
  border-width: 8px;
}

.DateDrop i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.DateDrop:hover i {
  opacity: 1;
}

.dateselectDiv {
  position: relative;
}

.dateselectBtn {
  background-color: transparent;
  border: 1px solid #fff;
  outline: 0;
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 3px;
}

.dateselectBtn i {
  font-size: 11px;
  opacity: 1;
}

.dateSlctDrop {
  padding: 10px 7px;
  position: absolute;
  background: #e9e9e9;
  width: 110%;
  left: -8px;
  height: 110px;
  overflow-y: auto;
  z-index: 10;
}

.dateSlctDrop::-webkit-scrollbar {
  display: none;
}

.inputDiv {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin: 3px 0px;
}

.inputDiv input {
  width: 10px;
  height: 10px;
  accent-color: #000;
}

.inputDiv p {
  font-size: 10px;
}

.sectorFilHead {
  background-color: #efdca9;
  padding: 3px;
  cursor: pointer;
}

.sectorFilHead p {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.FilDropHead {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.SecCounFil {
  background-color: #fff;
  margin: 15px 0px 30px;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: relative;
}

.inputPara {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.SecCounFil i {
  color: #858585;
  font-size: 12px;
  opacity: 0;
}

.SecCounFil:hover i {
  opacity: 1;
}

.SecCounDiv {
  position: relative;
}

.SecCounBtn {
  background-color: #f7f4e0;
  border: 1px solid #fff;
  outline: 0;
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 3px;
}

.SecCounBtn i {
  font-size: 11px;
  opacity: 1;
}

.SecCounDrop {
  padding: 10px 5px;
  position: absolute;
  bottom: -110px;
  background-color: #f7f4e0;
  width: 100%;
  margin: 0px auto;
  left: 0;
  height: 110px;
  overflow-y: auto;
  z-index: 10;
}

.SecCounDrop::-webkit-scrollbar {
  display: none;
}

.RestPage {
  width: calc(100% - 150px);
  height: auto;
  margin-left: auto;
  margin-bottom: 70px;
  position: relative;
  border-top: 6px solid #92bbac;
}

/***overall_view***/

.overall_view .left {
  display: flex;
  gap: 5px;
  height: 480px;
  overflow-y: auto;
}

.overall_view .leftBox {
  background-color: #fff;
  padding: 50px 25px 20px 25px;
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  box-shadow: 4px 5px 9px 1px rgba(0, 0, 0, 0.15);
}

.overall_view h6 {
  font-size: 15px;
  color: #151515;
  margin-bottom: 10px;
}

.overall_view .leftBox .in_box h5 {
  color: #118dff;
  font-size: 23px;
  margin-bottom: 10px;
}

.overall_view .leftBox .in_box span {
  background-color: #118dff;
  width: 100%;
  height: 2px;
  display: inline-block;
}

.overall_view .leftBox .in_box {
  margin-bottom: 20px;
}

.overall_view .right {
  background-color: #fff;
  width: 60%;
  padding: 15px;
  box-shadow: 4px 5px 9px 1px rgba(0, 0, 0, 0.15);
}

.overall_view .top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.selector {
  background-color: #f6e8c0;
  margin: 0 5px;
  font-size: 13px;
  padding: 3px 22px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
}

.selector:hover {
  background-color: #e6e6e6;
}

.pie-chart {
  /* background:
		radial-gradient(
			circle closest-side,
			white 0,
			white 40%,
			transparent 40%,
			transparent 100%,
			white 0
		),
		conic-gradient(
			#4e79a7 0,
			#4e79a7 62.3%,
			#f28e2c 0,
			#f28e2c 100%
      
	); */
  background: radial-gradient(
      circle closest-side,
      white 0,
      white 45%,
      transparent 46%,
      transparent 75%,
      white 0
    ),
    conic-gradient(
      #92bbac 90deg,
      #e68f96 90deg,
      #e68f96 180deg,
      #b2d7f9 180deg,
      #b2d7f9 270deg,
      #f0e199 270deg
    );
  position: relative;
  width: 100%;
  min-height: 333px;
}

.pie-chart h2 {
  position: absolute;
  margin: 1rem;
}

.pie-chart figcaption {
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: smaller;
  text-align: right;
}

.pie-chart span:after {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.4em;
  height: 0.8em;
  border-radius: 0.2em;
  background: currentColor;
}

/***my-pie**/
.pieChartBox {
  display: flex;
  align-items: center;
  justify-content: end;
}

.pieBox {
  position: relative;
}

.pie {
  --p: 70;
  --b: 15px;
  --c: #1edb6a;
  --w: 110px;
  transform: rotate(173deg);
  position: relative;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.middleBox {
  width: 100px;
  height: 100px;
  border-radius: 50%;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(188deg);
  /* position: absolute; */
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}

.middleBox h6 {
  color: #1b1d23;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 11px;
  margin-bottom: 7px;
}

.pie2 {
  --p: 18;
  --b: 15px;
  --c: #ffd260;
  --w: 110px;
  transform: rotate(86deg);
  display: inline-block;
  right: 0;
  position: absolute !important;
  top: 0;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.pie2:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.pie2:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}

.pie3 {
  --p: 10;
  --b: 15px;
  --c: #707070;
  --w: 110px;
  transform: rotate(79deg);
  display: inline-block;
  right: 0;
  position: absolute !important;
  top: 0;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.pie3:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.pie3:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}

.pieCnt {
  margin-left: 40px;
}

.pieCnt .green span.clrBox {
  background-color: #1edb6a;
  height: 18px;
  width: 18px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 5px;
}

.pieCnt .green span {
  color: #1edb6a;
}

.pieCnt a {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}

.pieCnt .yellow span.clrBox {
  background-color: #ffd260;
  height: 18px;
  width: 18px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 5px;
}

.pieCnt .yellow span {
  color: #ffd260;
}

.pieCnt .gray span.clrBox {
  background-color: #707070;
  height: 18px;
  width: 18px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 5px;
}

.pieCnt .gray span {
  color: #707070;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie2:before,
.pie2:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

/***loan_analysis**/
.barchartleft {
  width: 22%;
}

.barchartright {
  width: 78%;
}

.loan_analysis {
  background-color: #fff;
  padding: 15px;
  box-shadow: 4px 5px 9px 1px rgba(0, 0, 0, 0.15);
}

.loan_analysis .amount h5 {
  font-size: 12px;
  margin-right: 5px;
}

.loan_analysis .amount span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #b2d7f9;
  display: inline-block;
  margin-right: 3px;
}

.progressBox {
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: right;
  line-height: 32px;
}

.progressBox h6 {
  font-size: 11px;
  margin-bottom: 0;
  text-align: right;
}

.w3-light-grey {
  background-color: #92bbac;
  height: 30px;
  /* width: 83%; */
  position: relative;
}

.w3-light-grey span {
  position: absolute;
  right: 7px;
  font-size: 12px;
}

.w3-green {
  background-color: #b2d7f9;
  height: 100%;
  padding: 0 5px;
  font-size: 12px;
}

.sns_analysis {
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  height: auto;
  margin-top: 10px;
  margin-left: 5px;
  box-shadow: 4px 5px 9px 1px rgba(0, 0, 0, 0.15);
}

.sns_analysis .react-tabs__tab-list {
  display: flex;
  list-style-type: none;
  gap: 15px;
  /* padding-bottom: 10px; */
  margin-bottom: 0;
  justify-content: flex-end;
}

.sns_analysis .react-tabs__tab {
  align-items: center;
  background-color: #f9f5c4;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  justify-content: center;
  padding: 3px 15px;
  position: relative;
}

.sns_analysis .react-tabs__tab:focus::after {
  background-color: transparent;
}

/* .dataTables_wrapper>div:nth-of-type(2)>div>div {
  overflow-x: auto;
  margin-bottom: 10px;
  height: 340px;
  overflow-y: auto;
} */

.dataTables_wrapper {
  height: 340px;
  width: 100%;
  overflow: auto;
}

.Data_Table th {
  position: relative;
}

.Data_Table th:hover::after {
  content: "\f0d8";
  font-family: fontawesome;
  position: absolute;
  bottom: 0px;
  left: 3px;
  color: #fff;
}

.mdCmnTableDiv th {
  position: relative;
}

.mdCmnTableDiv th:hover::after {
  content: "\f0d8";
  font-family: fontawesome;
  position: absolute;
  bottom: 0px;
  left: 3px;
  color: #fff;
}

.Data_Table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.Data_Table::-webkit-scrollbar-thumb {
  background: #e68f96;
  border-radius: 10px;
}

.sns_analysis .table thead th {
  white-space: nowrap;
  background-color: #338f87;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
}

.sns_analysis td {
  white-space: nowrap;
  font-size: 12px;
  color: #151515;
  font-weight: 500;
  padding: 5px 10px;
}

.sns_analysis tr:nth-child(even) {
  background-color: #eeeded;
}

.sns_analysis td:hover {
  background-color: #cccccc;
}

.pagination {
  justify-content: end;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #b2d7f9;
  border-color: #b2d7f9;
}

/****repayment-tracking****/

.repayment_tracking {
  /* border-top: 6px solid #338f87; */
  padding-top: 5px;
}

.repayTab {
  display: flex;
  gap: 10px;
}

.repayment_tracking .top {
  background: #fff;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 600;
}

.Repaid {
  width: 40%;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: 4px 5px 9px 1px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.Repaid .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 36px 0 75px 0;
}

.Repaid h5 {
  color: #424242;
  font-size: 14px;
}

.Repaid h4 {
  color: #118dff;
  font-size: 15px;
}

.repaidimg {
  position: absolute;
  bottom: 5px;
}

/* .payment-due .table tbody tr {
  background-color: #f9f5c4;
}

.payment-due .table tbody tr:nth-of-type(2) {
  background-color: #d87e22;
}

.payment-due .table tbody tr:nth-of-type(3) {
  background-color: #d2e0fb;
} */

.potential {
  width: 60%;
  height: auto;
  background-color: #fff;
  box-shadow: 4px 5px 9px 1px rgba(0, 0, 0, 0.15);
  position: relative;
}

.potential:hover .RepaymntTooltip {
  visibility: visible;
}

.RepaymntTooltip {
  position: absolute;
  top: 5px;
  right: 15px;
  display: flex;
  gap: 15px;
  padding: 0px 10px;
  z-index: 10;
  visibility: hidden;
  background-color: #fff;
  cursor: pointer;
}

.RepaymntTooltip i {
  font-size: 14px;
}

.potential .apexcharts-menu-icon {
  display: none;
}

.potential .apexcharts-tooltip {
  min-width: 20%;
  width: auto;
  background-color: #fff !important;
  border-radius: 0px;
  border: none !important;
  box-shadow: 0 0 5px 0 #0000004d;
  padding: 5px 8px;
  white-space: wrap;
}

.potential .arrow_box {
  color: #151515;
  font-size: 12px;
  margin-bottom: 5px;
  /* display: flex;
  justify-content: space-between;
  gap: 3px; */
}

.potential .headspan {
  margin-left: auto;
}

.potential .paraspan {
  font-weight: 600;
}

/***responsive***/

@media (max-width: 1199px) {
  .headerUl {
    padding-bottom: 10px;
  }

  .headerUl li {
    min-width: 170px;
    width: 170px;
  }

  .headerUl li a {
    padding: 5px;
  }
}

@media (max-width: 991px) {
  .logo {
    margin: inherit;
  }

  .barchartleft {
    width: 30%;
  }

  .barchartright {
    width: 70%;
  }

  .sns_analysis {
    margin-top: 10px;
  }

  .overall_view .right {
    margin-top: 10px;
  }

  .Sidebar {
    transform: translateX(-100%);
    transition: 0.5s;
  }

  .open {
    transform: translateX(0);
    transition: 0.5s;
  }

  .Header {
    flex-direction: column;
    height: auto;
    align-items: inherit;
  }

  .main {
    width: 100%;
    margin-left: 0;
  }

  .topHeader {
    display: block;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    width: 100%;
  }

  .topHeader .bar a {
    font-size: 20px;
    color: #000;
  }

  /* repayment tab */

  .repayTab {
    flex-direction: column;
  }

  .Repaid {
    width: 100%;
  }

  .potential {
    width: 100%;
  }
}

@media (max-width: 767px) {
  /* header part start */

  .headerUl li a {
    font-size: 12px;
  }

  .headerPart {
    width: 100%;
  }

  .headerPart.sticky {
    width: 100%;
    left: 0;
  }

  .headmenuBtn {
    display: block;
  }

  .sidebarPart {
    left: -150px;
  }

  .sidebarPart.show {
    left: 0;
  }

  .RestPage {
    width: 100%;
  }

  .Repaid {
    height: auto;
    margin-bottom: 1rem;
  }

  .Repaid .box {
    width: 100%;
    margin: 15px 0 40px 0;
  }
}

@media (max-width: 575px) {
  /* header part */

  .headerPart {
    margin-bottom: 15px;
  }

  .headerUl li {
    width: auto;
  }
}

@media (max-width: 500px) {
  .overall_view .top {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .buttonBoth {
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  /* header part */

  .headerInn {
    gap: 15px;
  }

  .headerUl {
    padding-bottom: 0;
  }

  .headerUl li a {
    font-size: 11px;
    padding: 5px;
  }

  .headerUl li {
    min-width: 140px;
    width: 140px;
  }

  .headerUl::-webkit-scrollbar {
    display: none;
  }
}
