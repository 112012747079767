/* Header part */

.headerPart {
  width: calc(100% - 150px);
  margin-left: auto;
  height: auto;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}

.headerPart.sticky {
  position: fixed;
  top: 0;
  left: 150px;
  right: 0;
  width: calc(100% - 150px);
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.headerInn {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 8px; */
}

.headerInn i {
  color: #000;
  font-size: 15px;
}

.headerUl {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.headerUl li {
  width: 150px;
  background-color: #fff;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.headerUl li a {
  align-items: center;
  color: #000;
  display: block;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  padding: 5px;
}

.headerUl li a:hover {
  font-size: 12px;
}

.headerUl li a.active {
  background-color: #cccccc;
}

.headerUl li:hover {
  background-color: #cccccc;
}

.headmenuBtn {
  display: none;
}

.allAmntPara {
  font-size: 16px;
  font-style: italic;
  color: #151515;
  font-weight: 700;
  margin: 0;
}

.allAmntParaDiv {
  text-align: right;
  margin-bottom: 3px;
}

.btmheader {
  padding-bottom: 3px;
}

.headerPara {
  display: flex;
  justify-content: flex-end;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
}
