.dailySalesSection {
  background-color: #fff;
  padding: 10px 20px;
  min-height: 90vh;
  margin-bottom: 10px;
  padding-bottom: 20px;
  /* border-top: 6px solid #338f87; */
  position: relative;
}

.dailyAllSelecDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  position: absolute;
  right: 0;
  z-index: 2;
}

.snspArtDiv {
  border: 1px solid #4241414b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}
.starDropSelectDiv {
  width: 100%;
  padding: 5px 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.allSnsDivsDiv {
  width: 87%;
  position: relative;
}

.starSnSelecText {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 3px;
}

.starSelectAllDivsDiv {
  position: absolute;
  top: 0;
  right: 0;
}
.starSelectAllDivsDiv {
  width: 180px;
}

.dailySalesSection .alltoolTipDivsDiv {
  width: 100% !important;
}

.dailySaleLi {
  list-style-type: none;
  margin-bottom: 10px;
}

.dailySaleLi:last-child {
  margin-bottom: 0;
}

.dailySaleUl {
  display: flex;
  justify-content: center;
  gap: 100px;
  padding: 0;
}

.dailyQuantityTableDiv .table-bordered thead th {
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
  background-color: #338f86;
  color: #fff;
}

.dailyQuantityTableDiv .table-bordered tbody tr td {
  white-space: nowrap;
  font-size: 12px;
  color: #151515;
  font-weight: 500;
  padding: 5px 10px;
}

.dailyQuantityTableDiv .table-bordered tbody tr:nth-child(even) td {
  background-color: #eeeded;
}

.dailyProjectedTableDiv .table-bordered thead th {
  padding: 8px 0;
  background-color: #338f86;
  color: #fff;
}

.dailyProjectedTableDiv .table-bordered tbody tr td {
  padding: 5px 0;
  color: #000;
  font-weight: 500;
}

.dailyProjectedTableDiv .table-bordered tbody tr:nth-child(even) td {
  background-color: #eeeded;
}

.dailyTblInn {
  box-shadow: 1px 0px 6px 0px rgba(0, 0, 0, 0.3);
  padding: 5px 5px;
  margin-right: 12px;
  margin-left: 12px;
  width: 335px;
}

.dropDownSnLi {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.dropDownSnLi label {
  font-size: 0.7rem;
}

.dailyTableText {
  font-size: 15px;
  font-weight: 700;
  padding: 4px;
  line-height: normal;
  color: #595959;
}

.dailySalesSection .apexcharts-legend-marker {
  width: 25px !important;
  height: 2px !important;
}

.dailySalesSection .apexcharts-legend-series {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.dailySalesChartDiv {
  margin-bottom: 25px;
  position: relative;
}

.dailyTooltip {
  position: absolute;
  top: -15px;
  right: 15px;
  display: flex;
  gap: 15px;
  padding: 0px 10px;
  z-index: 10;
  visibility: hidden;
  background-color: #fff;
  cursor: pointer;
}

.dailyTooltip i {
  font-size: 14px;
}

.dailySalesChartDiv:hover .dailyTooltip {
  visibility: visible;
}

.focusIcon:first-child i {
  transform: rotate(45deg);
}

.dailySaleSkuDiv {
  width: 230px;
}

.dailySelecText {
  font-size: 10px;
  font-weight: 500;
  text-align: center;
}

.dailyAllSelecDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  position: absolute;
  right: 0;
  z-index: 2;
}

.dailySearUl {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  visibility: hidden;
  position: absolute;
  top: 50px;
  right: 0;
}

.dailySalesChartDiv:hover .dailySearUl {
  visibility: visible;
}

.dailySearLi {
  list-style-type: none;
  margin-right: 4px;
  background-color: #fff;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
}

.dailySearLi:first-child i {
  transform: rotate(45deg);
}

.dailySearLi:last-child {
  margin-right: 0;
}

.dailySalesSection .apexcharts-menu-icon {
  display: none;
}

.paraspan {
  font-weight: 600;
}

/* Responsive */
@media (max-width: 1399px) {
  .dailyTableText {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .dailySalesSection {
    height: auto;
  }
}

@media (max-width: 767px) {
  .dailyAllSelecDiv {
    position: static;
  }
}

@media (max-width: 575px) {
  .dailySalesSection {
    padding: 10px;
  }
}

@media (max-width: 479px) {
  .dailyAllSelecDiv {
    flex-direction: column-reverse;
    margin-bottom: 15px;
  }

  .dailySelecText {
    margin: 0 0 5px 0;
  }
}
