/* graph amount */

.graphPart {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  position: relative;
}

.graphData {
  width: 50%;
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.merChartTab {
  overflow: auto;
  margin-top: 10px;
  height: 470px;
}

.graphAmtDiv {
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  position: relative;
  /* height: 480px; */
  height: auto;
}
.dataTables_wrapper {
  height: 550px;
}
.graphInner {
  /* height: 430px; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20% 0;
}

.graphAmtDivInn {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 30px 0px;
}

.graphAmtDivInn::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #2277c4;
}

.graphPoint {
  font-size: 14px;
  color: #424242;
}

.graphNum {
  font-size: 15px;
  color: #118dff;
  margin: 5px 0px;
}

.graphamtFig {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  width: 50px;
}

.graphamtFig img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* pie chart */

.OverallPie {
  width: 60%;
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  padding: 5px;
  position: relative;
}

.OverallPie:hover .OverallTooltip {
  visibility: visible;
}

.OverallPie .react-tabs__tab--selected:focus::after {
  background-color: transparent;
}

.OverallPie .react-tabs__tab-list {
  border: none;
  display: flex;
  gap: 10px;
  margin: 0;
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 2;
}

.OverallPie .react-tabs__tab {
  width: 90px;
  align-items: center;
  background-color: #ede9cd;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  justify-content: center;
  padding: 3px 7px;
  position: relative;
}

.OverallPie .react-tabs__tab:hover {
  background-color: #cccccc;
}

.overallPieChart .apexcharts-legend {
  padding: 0;
}

.overallPieChart .apexcharts-tooltip {
  min-width: 40%;
  background-color: #fff !important;
  border-radius: 0px;
  border: none !important;
  box-shadow: 0 0 5px 0 #0000004d;
  padding: 5px 8px;
  white-space: wrap;
}

.overallPieChart .apexcharts-tooltip-title {
  background: #fff !important;
  border: none !important;
  margin: 0;
}

.OverallPie .arrow_box {
  color: #151515;
  font-size: 12px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  gap: 3px;
}

.OverallPie .headspan {
  margin-left: auto;
}

.OverallPie .paraspan {
  font-weight: 600;
}

/* overall bottom part */

.overallBtmPart {
  display: flex;
  gap: 10px;
}

/* Balance loan analysis */

.blncLoanAnlys {
  width: 50%;
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  position: relative;
}

.OverallTooltip {
  position: absolute;
  top: 30px;
  right: 5px;
  display: flex;
  gap: 15px;
  padding: 0px 10px;
  z-index: 10;
  visibility: hidden;
  background-color: #fff;
  cursor: pointer;
}

.OverallTooltip i {
  font-size: 13px;
}

.blncLoanAnlys:hover .OverallTooltip {
  visibility: visible;
}

.blncLoanAnlys .apexcharts-tooltip {
  min-width: 48%;
  background-color: #fff;
  border-radius: 0px;
  border: none !important;
  box-shadow: 0 0 5px 0 #0000004d;
  padding: 5px 8px;
  white-space: wrap;
}

.blncLoanAnlys .arrow_box {
  color: #151515;
  font-size: 11px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  gap: 3px;
}

.blncLoanAnlys .headspan {
  font-weight: 400;
  width: 40%;
}

.blncLoanAnlys .paraspan {
  width: 60%;
  text-align: start;
  font-weight: 600;
}

/* Responsive */

@media (max-width: 1199px) {
  /* overall view page */

  .graphPoint {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  /* overall view page */

  .graphPart {
    flex-direction: column;
  }

  .graphData {
    width: 100%;
  }

  .OverallPie {
    width: 100%;
  }

  .graphInner {
    padding: 0px;
  }

  .graphAmtDivInn {
    margin: 20px 0px;
  }

  .overallBtmPart {
    flex-direction: column;
  }

  .blncLoanAnlys {
    width: 100%;
  }

  .sns_analysis {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .OverallPie .react-tabs__tab-list {
    position: static;
    margin-bottom: 10px;
  }

  .overall_view .graphInner {
    height: 350px;
  }
}
