/* Top Investor part */

.topInvestor {
  background-color: #fff;
}

.InvestorPageTop {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  /* border-top: 6px solid #338f87; */
}

.InvestorPgLeft {
  width: 40%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 10px 15px 0 #a5a5a5;
  margin-bottom: 10px;
}

.InvestorPgRight {
  width: 60%;
  margin-left: 10px;
  margin-bottom: 10px;
}

.apexcharts-legend-marker {
  border-radius: 50% !important;
}

/* Earned return */

.earnedAnalysis {
  width: 100%;
  height: 100%;
}

.earnedTopHead {
  color: #424242;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 15px 5px;
  border-bottom: 3px solid #b1b1b1;
}

.earnedBtm {
  padding: 20px 10px;
  padding-top: 10px;
  display: flex;
  padding-left: 50px;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 20px;
  gap: 5px;
  height: 80%;
  overflow: hidden;
}

.earnedDiv {
  display: flex;
  justify-content: space-between;
}

.earnedLeft {
  width: 50%;
}

.earnedLeft p {
  color: #151515;
  font-size: 13px;
  font-weight: 500;
}

.earnedRight {
  width: 48%;
  text-align: center;
  position: relative;
}

.earnerNum {
  color: #3786d5;
  font-size: 17px;
  font-weight: 600;
}

/* tooltip */

.returnAnTooltip {
  display: flex;
  gap: 5px;
  position: absolute;
  left: 0;
  transform: translateX(-50%);
  top: -22px;
  z-index: 10;
  visibility: hidden;
}

.returnIcon {
  background-color: #fff;
  padding: 0px 6px;
  box-shadow: 0 2px 10px 0 #0000004d;
  position: relative;
}

.returnIcon i {
  font-size: 11px;
  color: #747474;
}

.earnedRight:hover .returnAnTooltip {
  visibility: visible;
}

.returnIcon:first-child i {
  transform: rotate(45deg);
}

/* horizontal bar */

.InvestorBar {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 1px 10px 15px 0 #a5a5a5;
}

.InvestorBar .apexcharts-tooltip {
  /* min-width: 30%; */
  width: auto;
  background-color: #fff !important;
  border-radius: 0px;
  border: none !important;
  box-shadow: 0 0 5px 0 #0000004d;
  padding: 5px 8px;
  white-space: wrap;
}

.InvestorBar .arrow_box {
  color: #151515;
  font-size: 12px;
  margin-bottom: 5px;
  /* display: flex;
    justify-content: space-between;
    gap: 3px; */
}

.InvestorBar .headspan {
  margin-left: auto;
}

.InvestorBar .paraspan {
  font-weight: 600;
}

/* investor data table */

.investorTable {
  background-color: #fff;
  padding: 10px 5px 5px;
}

.investorTable .react-tabs__tab-list {
  display: flex;
  list-style-type: none;
  gap: 15px;
  padding-bottom: 10px;
}

.investorTable .react-tabs__tab {
  align-items: center;
  background-color: #f9f5c4;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  justify-content: center;
  padding: 3px 15px;
  position: relative;
}

.investorTable .react-tabs__tab:hover {
  background-color: #ccc;
}

.investorTable .react-tabs__tab a {
  color: #151515;
  text-decoration: none;
}

.investorTable .react-tabs__tab a:hover {
  color: #151515;
  text-decoration: none;
}

.InvesttabLink {
  background-color: #f9f5c4;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  padding: 3px 15px;
  text-decoration: none;
  position: relative;
}

.InvesttabLink:hover {
  background-color: #ccc;
  color: #151515;
  text-decoration: none;
}

.InvesttabLink:hover .pmTooltip {
  visibility: visible;
}

.pmTooltip {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #747474;
  font-size: 11px;
  left: 0;
  padding: 8px;
  position: absolute;
  top: -45px;
  visibility: hidden;
  width: 140px;
  z-index: 10;
}

.pmTooltip:after {
  border: 12px solid #0000;
  border-top-color: #fffefe;
  bottom: -20px;
  content: "";
  display: block;
  height: 22px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.investorTable .react-tabs__tab:hover .pmTooltip {
  visibility: visible;
}

.invTabPara {
  color: #595959;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
}

.dataTables_info,
.dataTables_paginate,
.dataTables_length,
.mdb-datatable-filter {
  display: none;
}

.investorTable th {
  background-color: #338f86;
  border-right: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.investorTable th:hover::after {
  content: "\f0d8";
  font-family: fontawesome;
  position: absolute;
  bottom: 0px;
  left: 3px;
  color: #fff;
}

.investorTable tr:nth-child(even) {
  background-color: #eeeded;
}

.investorTable tr:hover {
  background-color: #eeeded;
}

.investorTable td {
  color: #151515;
  font-size: 11px;
  font-weight: 500;
  min-width: 130px;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.investorTable td:hover {
  background-color: #ccc;
}

.investorFirstRow {
  display: flex;
  gap: 10px;
}

.invAmtSec {
  width: 40%;
  display: flex;
  gap: 5px;
}

/* return analysis area chart */

.returnAnDiv {
  width: 60%;
  height: auto;
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 #00000073;
  position: relative;
}

.returnAnDiv .apexcharts-menu-icon {
  display: none;
}

.returnAnDiv
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background-color: transparent !important;
  border: none !important;
}

.returnAnDiv .apexcharts-tooltip-title {
  margin-bottom: 0 !important;
}

.returnAnDiv .apexcharts-tooltip-series-group {
  padding: 0 6px !important;
}

.returnAnDiv .apexcharts-tooltip {
  border-radius: 0px;
}

.returnAnDiv .apexcharts-xaxistooltip {
  display: none;
}

.snApex .apexcharts-tooltip-marker {
  background-color: #f0e199 !important;
}

.PortApex .apexcharts-tooltip-marker {
  background-color: #efb5b9 !important;
}

.returnAnDiv .react-tabs__tab-list {
  border: none;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 12px;
}

.returnAnDiv .react-tabs__tab {
  background-color: #ede9cd;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.returnAnDiv .react-tabs__tab--selected {
  border: none;
  border-radius: 0;
}

.portTooltip {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #747474;
  font-size: 11px;
  left: 50%;
  transform: translate(-50%);
  padding: 8px;
  position: absolute;
  top: -45px;
  visibility: hidden;
  z-index: 10;
  width: 140px;
}

.portTooltip:after {
  border: 12px solid #0000;
  border-top-color: #fffefe;
  bottom: -20px;
  content: "";
  display: block;
  height: 22px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.returnAnDiv .react-tabs__tab:hover .portTooltip {
  visibility: visible;
}

.apexVerticalTxt {
  writing-mode: vertical-lr;
  position: absolute;
  top: 40%;
  left: 5px;
  transform: translateY(-50%);
  transform: rotate(180deg);
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

/* dropdown */

/* investor analysis table */

.investorSmryBtm {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 #00000073;
  padding: 15px;
}

.invstTblHead {
  color: #151515;
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 8px;
}

.investorSmryBtm .table thead th {
  white-space: nowrap;
  background-color: #338f87;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
}

.investorSmryBtm th:hover::after {
  content: "\f0d8";
  font-family: fontawesome;
  position: absolute;
  bottom: 0px;
  left: 3px;
  color: #fff;
}

.investorSmryBtm td {
  white-space: nowrap;
  font-size: 12px;
  color: #151515;
  font-weight: 500;
  padding: 5px 10px;
}

.investorSmryBtm tr:nth-child(even) {
  background-color: #eeeded;
}

.investorSmryBtm td:hover {
  background-color: #cccccc;
}

/* responsive */

@media (max-width: 1199px) {
  .earnedTopHead {
    font-size: 13px;
  }

  .earnerNum {
    font-size: 14px;
  }

  .earnedBtm {
    height: 70%;
  }
}

@media (max-width: 991px) {
  .InvestorPageTop {
    flex-direction: column;
  }

  .InvestorPgLeft,
  .InvestorPgRight {
    width: 100%;
  }

  .earnedBtm {
    padding: 20px;
    gap: 30px;
  }

  .earnedBtm {
    height: auto;
  }

  .earnedRight {
    text-align: end;
  }

  .investorFirstRow {
    flex-direction: column;
  }

  .invAmtSec {
    width: 100%;
  }

  .returnAnDiv {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .investorTable .react-tabs__tab-list {
    gap: 10px;
    justify-content: flex-start;
    overflow-x: auto;
  }

  .investorTable .react-tabs__tab,
  .InvesttabLink {
    min-width: 160px;
    width: 100%;
    white-space: nowrap;
    font-size: 11px;
  }

  .earnedTopHead {
    font-size: 12px;
  }

  .earnedBtm {
    gap: 20px;
    padding: 25px 15px;
  }

  .earnerNum,
  .earnedLeft p {
    font-size: 12px;
  }

  /* investor analysis page start */

  .earnedTopHead {
    padding: 10px 5px;
  }

  .invAmtSec .graphInner {
    height: 430px;
  }

  .returnAnDiv .react-tabs__tab-list {
    justify-content: flex-start;
    margin: 0;
    flex-wrap: wrap;
  }

  .returnAnDiv .react-tabs__tab {
    width: 47%;
  }

  .invstTblHead {
    font-size: 14px;
  }

  .investorSmryBtm .table thead th {
    font-size: 12px;
  }

  .investorSmryBtm td {
    font-size: 11px;
  }

  /* investor analysis page end */
}
