.snuSumSection {
    background-color: #fff;
    padding: 10px 10px 50px;
    /* height: 100vh; */
    /* border-top: 6px solid #338f87; */
}

.snuTabsMainDiv {
    position: relative;
}
.starSelectAllDivsDiv{
    width: 180px;
    margin-top: 0px;
}

.starSnSelecText {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3px;
}

.starSnDropSelectDiv {
    width: 100%;
    /* padding: 5px 0; */
    /* border: 1px solid #494545a4; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.allSkuSnsDivsDiv {
    width: 95%;
    position: relative;
}

.snsSkupArtDiv {
    border: 1px solid #4241414b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}

.snsSkupArtDiv span {
    font-size: 12px;
}

.dropSnDownStartDiv {
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #4e4b4b2f;
    position: absolute;
    top: 27px;
    left: 0;
    z-index: 9;
}

.dropSnDownStartDiv::-webkit-scrollbar-thumb {
    background: #c2bdbd;
}

.dropSnDownDisplayStartDiv {
    display: none;
}

.dropSkuDownSnUl {
    margin: 0;
    padding: 0;
}

.dropSkuDownSnLi {
    margin-bottom: 2px;
}

.dropSkuDownSnLi:last-child {
    margin-bottom: 0;
}

.dropSkuDownSnLi input {
    margin-right: 5px;
    accent-color: #000;
    width: 10px;
    height: 10px;
}

.dropSkuDownSnLi label {
    font-size: 12px;
    font-weight: 400;
}

.snuTabsMainDiv .react-tabs__tab-list {
    border: 0;
    display: flex;
    gap: 15px;
    padding-bottom: 10px;
}

.snuTabsMainDiv .react-tabs__tab--selected {
    background-color: transparent;
    border: 0;
}

.snuTabsMainDiv .react-tabs__tab {
    align-items: center;
    background-color: #f9f5c4;
    box-shadow: 1px 2px 4px 0 #0000004d;
    color: #151515;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 22px;
    justify-content: center;
    padding: 3px 15px;
    position: relative;
}

.snuTabsMainDiv .react-tabs__tab:last-child {
    margin-right: 0;
}

.snuSumSection .starSelectAllDivsDiv {
    position: absolute;
    top: 0;
    right: 0;
}

/* .mdbTableParDiv .table-bordered td,
.mdbTableParDiv .table-bordered th {
    width: 20%;
} */

/* .mdbTableParDiv {
    min-height: 78vh;
} */

/* .mdbTableParDiv2 {
    overflow-x: auto;
    min-height: 78vh;
} */

.mdbTableParDiv2::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}

.mdbTableParDiv2 ::-webkit-scrollbar-thumb {
    background: #afaead !important;
}

.mdbTableParDiv table {
    margin-bottom: 0;
}

.mdbTableParDiv2 table {
    width: 2000px;
    margin-bottom: 0;
}

.mdbTableParDiv .dataTables_wrapper>div:nth-of-type(2)>div>div {
    overflow-x: visible;
    overflow-y: visible !important;
    height: auto !important;
}

.mdbTableParDiv2 .dataTables_wrapper>div:nth-of-type(2)>div>div {
    height: auto !important;
}

.mdCmnTableDiv .table-bordered thead th {
    white-space: nowrap;
    background-color: #338f87;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px;
}

.mdCmnTableDiv .table-bordered tbody td {
    white-space: nowrap;
    font-size: 12px;
    color: #151515;
    font-weight: 500;
    padding: 5px 10px;
}

.mdCmnTableDiv tr:nth-child(even) {
    background-color: #eeeded;
}

.mdCmnTableDiv tr:hover{
    background-color: #eeeded;
}

.mdCmnTableDiv td:hover{
    background-color: #cccccc;
}

/* .mdbTableParDiv2 .table-bordered tbody tr:nth-child(3) td {
    background-color: #f18c26;
}

.mdbTableParDiv2 .table-bordered tbody tr:nth-child(5) td {
    background-color: #d2e0fb;
} */

.mdbPrevText {
    color: #595959;
    font-size: 15px;
    font-weight: 700;
    margin: 0 0 5px 0;
}

@media (max-width: 1365px) {
    .mdbTableParDiv table {
        width: 1366px;
    }

    .mdbTableParDiv {
        overflow-x: auto;
    }
}

@media (max-width: 991px) {
    .mdbTableParDiv2::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 767px) {
    .snuTabsMainDiv .react-tabs__tab-list {
        margin-bottom: 50px;
    }

    .snuSumSection .starSelectAllDivsDiv {
        top: 45px;
    }
}

@media (max-width: 575px) {
    .snuTabsMainDiv .react-tabs__tab-list {
        margin-bottom: 85px;
        display: flex;
        justify-content: center;
    }

    .snuSumSection .starSelectAllDivsDiv {
        top: 50px;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

@media (max-width: 479px) {
    .snuTabsMainDiv .react-tabs__tab {
        font-size: 10px;
        margin-right: 7px;
        padding: 5px 10px;
    }

    .snuSumSection .starSelectAllDivsDiv {
        top: 40px;
    }
}