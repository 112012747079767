.react-tabs__tab-list {
  border: none;
}

.react-tabs__tab--selected {
  border: none;
  border-radius: 0;
}

.mrchnt_summry {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* border-top: 6px solid #338f87; */
}

/* merchant top data start */

.graphAmtSec {
  width: 50%;
  display: flex;
  gap: 5px;
}

.buttonRow {
  display: flex;
  gap: 20px;
  margin: 5px;
}
.topSkuBtn,
.amountDueAnalysisBtn {
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  background-color: #f4edc9;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  padding: 5px 10px;
  font-size: 14px;
}

.graphAmtDiv {
  width: 50%;
  background-color: #fff;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  position: relative;
  height: auto;
  padding: 0px 15px;
}

.graphInner {
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graphAmtDivInn {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 20px 0px;
}

.graphAmtDivInn::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: #3c87cb;
}

.graphPoint {
  font-size: 13px;
  color: #424242;
}

.graphNum {
  font-size: 15px;
  color: #3c87cb;
  font-weight: 500;
  margin: 5px 0px;
}

/* merchant top chart start */

.merChartTab {
  width: 70%;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 5px;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
  padding: 15px 5px;
  position: relative;
}

.selectDropDiv {
  position: absolute;
  margin-top: 10px;
  right: 10px;
  top: 0;
}

.snIconSpan {
  margin-left: auto;
}
.selectDroptext {
  font-size: 10px;
  font-weight: 500;
}

.merChartTab .react-tabs__tab-list {
  display: flex;
  justify-content: space-between;
  /* align-items: baseline; */
  gap: 10px;
  border: none;
}

.merChartTab .react-tabs__tab {
  align-items: center;
  background-color: #f9f5c4;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  width: 170px;
  height: 22px;
  justify-content: center;
  padding: 3px 6px;
  position: relative;
}

.merChartTab .react-tabs__tab--selected {
  border: none;
  border-radius: 0;
  background-color: none;
}

.merChartTab .react-tabs__tab:hover .pmTooltip {
  visibility: visible;
}

.merChartTooltip {
  position: absolute;
  top: 45px;
  right: 15px;
  display: flex;
  gap: 8px;
  padding: 5px;
  /* z-index: 1; */
  visibility: hidden;
  cursor: pointer;
}

.merChartTooltip i {
  color: rgba(134, 134, 134);
  box-shadow: 0px 0px 3px 2px rgba(134, 134, 134, 0.3);
  padding: 3px 5px;
}

.merChartTab:hover .merChartTooltip {
  visibility: visible;
}

.copyIcon {
  position: relative;
}

.copyIconPara {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #747474;
  font-size: 11px;
  right: 0px;
  left: -60px;
  padding: 5px 2px;
  position: absolute;
  top: -35px;
  visibility: hidden;
  width: 140px;
  z-index: 10;
}

.copyIconPara:after {
  border: 12px solid #0000;
  border-top-color: #fffefe;
  bottom: -20px;
  content: "";
  display: block;
  height: 22px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.copyIcon:hover .copyIconPara {
  visibility: visible;
}

.focusIcon {
  position: relative;
}

.focusPara {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #747474;
  font-size: 11px;
  right: 0px;
  left: -25px;
  padding: 5px 5px;
  position: absolute;
  top: -35px;
  visibility: hidden;
  width: 70px;
  z-index: 10;
  text-align: center;
}

.focusPara:after {
  border: 12px solid #0000;
  border-top-color: #fffefe;
  bottom: -20px;
  content: "";
  display: block;
  height: 22px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.focusIcon:hover .focusPara {
  visibility: visible;
}

.moreIcon {
  position: relative;
}

.moreIconPara {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #747474;
  font-size: 11px;
  right: 0px;
  left: -110px;
  padding: 5px 5px;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 100px;
  z-index: 10;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;
}

.moreIconPara::after {
  border: 10px solid #0000;
  border-left-color: #fffefe;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -18px;
}

.moreIcon:hover .moreIconPara {
  visibility: visible;
}

.moreIconMenu {
  background-color: #fff;
  position: absolute;
  left: -184px;
  top: 0;
  z-index: 3;
  width: 180px;
  padding: 0px 10px;
  box-shadow: 0 2px 10px 3px #0000004d;
}

.moreIconMenu ul {
  list-style-type: none;
}

.moreIconMenu ul li {
  font-size: 12px;
  color: #151515;
  font-weight: 500;
  margin: 4px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.moreIconMenu ul li i {
  font-size: 11px;
  box-shadow: none;
  padding: 0;
  margin-right: 5px;
}

.shareLi {
  position: relative;
}

.shareMenu {
  position: absolute;
  width: 150px;
  left: -155px;
  top: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px 3px #0000004d;
  padding: 0px 10px;
  visibility: hidden;
}

.shareMenu li {
  display: flex;
  justify-content: flex-start !important;
  gap: 5px;
}

.shareLi:hover .shareMenu {
  visibility: visible;
}

.sortLi {
  position: relative;
}

.sortAxisMenu {
  position: absolute;
  width: 150px;
  left: -155px;
  top: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px 3px #0000004d;
  padding: 0px 10px;
  visibility: hidden;
}

.sortAxisMenu li {
  display: flex;
  justify-content: flex-start !important;
  gap: 5px;
}

.sortLi:hover .sortAxisMenu {
  visibility: visible;
}

.sortbyUl {
  border-top: 1px solid #a5a5a5;
}

/* graph progress bar */

.overallchart {
  width: 60%;
}

.overallChartTop {
  background-color: #fff;
  padding: 5px 20px 5px 5px;
  margin-top: 5px;
}

.overallChartHead {
  font-size: 14px;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.overallChartUl {
  list-style-type: none;
  padding-top: 15px;
}

.overallChartUl li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.chartLiLeft {
  width: 22%;
  position: relative;
  display: inline-block;
}

.chartLiTxt {
  font-size: 11px;
  font-weight: 500;
  text-align: end;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ChartLitooltip {
  position: absolute;
  left: 100%;
  top: 100%;
  width: auto;
  border: 1px solid black;
  background-color: #f1f0f0;
  z-index: 10;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #000;
  width: 100%;
  text-align: center;
  visibility: hidden;
}

.chartLiLeft:hover .ChartLitooltip {
  visibility: visible;
}

.chartLiRight {
  width: 78%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.progresstooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -130px;
  z-index: 10;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: auto;
  height: auto;
  padding: 5px 10px;
  gap: 15px;
  visibility: hidden;
}

.progresstooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  display: block;
  height: 20px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent transparent #fffefe;
}

.chartLiRight:hover .progresstooltip {
  visibility: visible;
}

.progresstooltip ul {
  text-decoration: none;
}

.progresstooltip ul li {
  color: #151515;
  font-size: 11px;
  font-weight: 500;
}

.overallChartTop .progress {
  background-color: #fff;
  border-radius: 0;
  height: 20px;
}

.overallChartTop .progress-bar {
  background-color: #f5c4af;
  position: relative;
}

.Chartspan {
  position: absolute;
  right: 5px;
  color: #000;
  font-size: 10px;
  font-weight: 500;
}

/* amount due analysis */

.overallChartBtm {
  background-color: #fff;
  /* box-shadow: 1px 10px 15px 0px #a5a5a5; */
  padding: 5px 20px 5px 5px;
  margin-top: 15px;
}

.amtDueBtnDiv {
  display: flex;
  gap: 8px;
}

.amtDueBtn {
  background-color: transparent;
  border: none;
  outline: 0;
  font-size: 11px;
  font-weight: 500;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
}

.amtBlankDiv {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.overallChartBtm .progress {
  background-color: #f0e199;
  border-radius: 0;
  position: relative;
  overflow: visible;
  height: 20px;
}

.overallChartBtm .progress-bar {
  background-color: #92bbac;
  position: relative;
  overflow: visible;
}

.progInntooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -100px;
  z-index: 15;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: auto;
  height: auto;
  padding: 5px 10px;
  gap: 15px;
  display: inline-block;
  visibility: hidden;
}

.progInntooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  display: block;
  height: 20px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent transparent #fffefe;
}

.progInntooltip ul {
  text-decoration: none;
}

.progInntooltip ul li {
  color: #151515;
  font-size: 11px;
  font-weight: 500;
  margin: 20px 0px;
}

.overallChartBtm .progress-bar:hover .progInntooltip {
  visibility: visible;
}

.barspan {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  color: #000;
  font-size: 9px;
  font-weight: 500;
}

.progspan {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  color: #000;
  font-size: 9px;
  font-weight: 500;
}

.merselect {
  position: absolute;
  right: 5px;
  top: 18px;
  z-index: 2;
}

.merselectDiv {
  width: 160px;
  height: 22px;
  position: relative;
  background-color: #fff;
  border: 1px solid #4241414b;
  padding-left: 5px;
}

.merselectBtn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: 0;
  background-color: transparent;
  font-size: 11px;
}

.merSlctDrop {
  position: absolute;
  /* bottom: -150px; */
  left: 0;
  z-index: 20;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #ccc;
}

.merSlctDrop input {
  width: 10px;
  height: 10px;
  accent-color: #151515;
}

.inputDiv {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 3px 0px;
}

.merSlctDrop p {
  font-size: 11px;
  color: #151515;
  font-weight: 500;
}

.merSlctDrop::-webkit-scrollbar {
  width: 5px;
}

.merSlctDrop::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 0;
}

.merSlctDrop::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
}

.MerAmtDueChart .apexcharts-tooltip {
  background-color: #fff !important;
  border-radius: 0px;
  border: none !important;
  box-shadow: 0 0 5px 0 #0000004d;
  padding: 5px 8px;
  white-space: wrap;
}

.MerAmtDueChart .arrow_box {
  color: #151515;
  font-size: 12px;
  margin-bottom: 5px;
}
/* .MerAmtDueChart {
  height: 50vh;
  overflow: scroll;
} */

.MerAmtDueChart .headspan {
  margin-left: auto;
}

.MerAmtDueChart .paraspan {
  font-weight: 600;
}

/* merchant data table start */

.merchantTable {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 1.5rem;
  box-shadow: 1px 10px 15px 0px #a5a5a5;
}

.TableHead {
  color: #595959;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.merchantTable th {
  background-color: #338f86;
  border-right: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.merchantTable th:hover::after {
  content: "\f0d8";
  font-family: fontawesome;
  position: absolute;
  bottom: 0px;
  left: 3px;
  color: #fff;
}

.merchantTable td {
  border: 1px solid #e5e5e5;
  color: #151515;
  font-size: 11px;
  font-weight: 500;
  min-width: 130px;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.merchantTable .react-tabs__tab-list {
  display: flex;
  list-style-type: none;
  gap: 15px;
  padding-bottom: 10px;
}

.merchantTable .react-tabs__tab {
  align-items: center;
  background-color: #f9f5c4;
  box-shadow: 1px 2px 4px 0 #0000004d;
  color: #151515;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  justify-content: center;
  padding: 3px 15px;
  position: relative;
}

.merchantTable .react-tabs__tab:hover {
  background-color: #ccc;
}

.merchantTable .react-tabs__tab:hover .pmTooltip {
  visibility: visible;
}

.merchantTable tr:nth-child(even) {
  background-color: #eeeded;
}

.merchantTable tr:hover {
  background-color: #eeeded;
}

.merchantTable td:hover {
  background-color: #cccccc;
}

/* active investment */

.activeInvst table tbody tr td:nth-child(6) {
  background-color: #a7cbc3;
}

.activeInvst table tbody tr td:nth-child(6):hover {
  background-color: #7dafa4;
}

.activeInvst table tbody tr td:nth-child(10) {
  background-color: rgb(235, 197, 197);
}

.activeInvst table tbody tr td:nth-child(10):hover {
  background-color: rgb(202, 161, 161);
}

/* default investment */

.defaultInvst table tbody tr td:nth-child(6) {
  background-color: rgb(235, 197, 197);
}

.defaultInvst table tbody tr td:nth-child(6):hover {
  background-color: rgb(202, 161, 161);
}

.defaultInvst table tbody tr td:nth-child(12) {
  background-color: #a7cbc3;
}

.defaultInvst table tbody tr td:nth-child(12):hover {
  background-color: #7dafa4;
}

/* Responsive */

@media (max-width: 1299px) {
  .merChartTab .react-tabs__tab {
    font-size: 10px;
  }

  .merselectBtn {
    font-size: 10px;
  }
}

@media (max-width: 1199px) {
  .graphNum {
    font-size: 13px;
  }

  .graphPoint {
    font-size: 12px;
  }

  .mrchnt_summry {
    flex-wrap: wrap;
  }

  .graphAmtSec {
    width: 100%;
    /* margin-bottom: 10px; */
  }

  .merChartTab {
    width: 100%;
  }

  .merChartTab .react-tabs__tab-list {
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 575px) {
  .merchantTable .react-tabs__tab-list {
    overflow-x: auto;
    justify-content: flex-start;
  }

  .merchantTable .react-tabs__tab {
    min-width: 160px;
    white-space: nowrap;
  }

  .merChartTab .react-tabs__tab-list {
    flex-wrap: wrap;
    gap: 5px;
  }

  .merChartTab .react-tabs__tab {
    width: 49%;
    font-size: 9px;
  }

  .merselectDiv {
    min-width: 160px;
  }

  .graphInner {
    height: auto;
  }

  .selectDroptext {
    display: none;
  }
}

@media (max-width: 480px) {
  .barspan,
  .progspan {
    font-size: 7px;
    right: 1px;
  }

  .graphPoint {
    font-size: 11px;
  }

  .graphNum {
    font-size: 12px;
  }

  .moreIconMenu {
    left: -112px;
    width: 135px;
    padding: 0px 6px;
    top: 20px;
  }

  .shareMenu {
    width: 140px;
    left: -145px;
    padding: 0px 6px;
  }
}
