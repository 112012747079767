.structureSection {
  background-color: #fff;
  padding: 10px 20px;
  /* border-top: 6px solid #338f87; */
}

.strucFirstDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 5px;
}

.strucHead {
  color: #595959;
  font-size: 15px;
  font-weight: 700;
}

.starSelecText {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.starSelectAllDivsDiv {
  width: 180px;
  margin-top: 150px;
}

.starDropSelectDiv {
  width: 100%;
  padding: 5px 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allSnsDivsDiv {
  width: 87%;
  position: relative;
}

.snspArtDiv {
  border: 1px solid #4241414b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}

.snspArtDiv span {
  font-size: 12px;
}

.dropDownStartDiv {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #4e4b4b2f;
  position: absolute;
  top: 27px;
  left: 0;
  z-index: 9;
}

.dropDownStartDiv::-webkit-scrollbar-thumb {
  background: #c2bdbd;
}

.dropDownDisplayStartDiv {
  display: none;
}

.dropDownSnUl {
  margin: 0;
  padding: 0;
}

.dropDownSnLi {
  margin-bottom: 2px;
}

.dropDownSnLi:last-child {
  margin-bottom: 0;
}

.dropDownSnLi input {
  margin-right: 5px;
  accent-color: #000;
}

.dropDownSnLi label {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}

.allToolTipsUlDiv {
  margin-bottom: 0;
  padding: 0;
}

.toolTipDiv {
  height: 85px;
  box-shadow: 2px 3px 7px 0 #302f2f81;
  background-color: #fff;
  padding: 8px;
  position: relative;
}

.toolTipInnerDiv {
  border: 1px solid #44414163;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolInSmallTexts {
  font-size: 12px;
  color: #000;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 4px 0;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolInSmallDates {
  font-size: 14px;
  color: #118dff;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-align: center;
}

.toolTipBtns {
  background-color: transparent;
  border: 0;
  font-size: 12px;
  margin-right: 3px;
  position: relative;
}

.upperTooltip {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 #0000004d;
  color: #747474;
  font-size: 11px;
  right: 0px;
  left: -25px;
  padding: 5px 5px;
  position: absolute;
  top: -35px;
  visibility: hidden;
  width: 70px;
  z-index: 10;
  text-align: center;
}

.upperTooltip:after {
  border: 12px solid #0000;
  border-top-color: #fffefe;
  bottom: -20px;
  content: "";
  display: block;
  height: 22px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.toolTipBtns:hover .upperTooltip {
  visibility: visible;
}

.toolTipBtns:first-child i {
  transform: rotate(45deg);
}

.toolTipBtns:last-child {
  margin-right: 0;
}

.toolTipBtnsDiv {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
  visibility: hidden;
  background-color: #fff;
}

.mainAllToUlDiv {
  padding: 15px 0px !important;
}

.allToolPrntDiv {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 10px !important;
}

.alltoolTipDivsDiv {
  width: 12%;
  margin-right: 10px;
  margin-bottom: 5px;
  position: relative;
}

.alltoolTipDivsDiv:hover .strucTooltip {
  visibility: visible;
}

.alltoolTipDivsDiv:hover .toolTipBtnsDiv {
  visibility: visible;
}

.strucTooltip {
  position: absolute;
  top: -20px;
  right: 15px;
  display: flex;
  gap: 15px;
  padding: 0px 10px;
  z-index: 10;
  visibility: hidden;
  background-color: #fff;
  cursor: pointer;
}

.strucTooltip i {
  font-size: 14px;
}

.allToolTipsLi {
  display: flex;
  margin-bottom: 7px;
}

.allToolTipsLi:last-child {
  margin-bottom: 0;
}

.allToolTipsLi:nth-child(odd) {
  justify-content: space-between;
}

.allToolTipsLi:nth-child(even) {
  justify-content: space-around;
}

.structureBtmPartSection {
  padding: 5px 0;
  border-top: 8px solid #92bbac;
  margin-top: 8px;
  /* margin-bottom: 20px; */
}

.structureBtmPartSection .apexcharts-text tspan {
  font-weight: 500;
  font-size: 11px;
}

.topSkusDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.topSkuHead {
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  color: #595959;
}

.topSkuTenBtnsDiv {
  display: flex;
}

.topSkuTenBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4edc9;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
  /* width: 180px; */
  padding: 3px 15px;
  box-shadow: 3px 3px 5px 0 #6e6c6cb7;
  border: 0;
}

.topSkuTenBtns:last-child {
  margin-right: 0;
}

.topSkuTenBtns:hover {
  color: #000;
  text-decoration: none;
  background-color: #e6e6e6;
}

.StructureFlex {
  display: flex;
  gap: 15px;
}

.struCOlInDiv {
  margin-top: 15px;
  margin-left: 5px;
  width: 50%;
  background-color: #fff;
  padding: 15px;
  box-shadow: 2px 2px 5px 0 #535151b2;
  border-radius: 10px;
}

.StructureFlex .apexcharts-menu-icon {
  display: none;
}

.StructureFlex .apexcharts-tooltip {
  width: auto;
  background-color: #fff !important;
  border-radius: 0px;
  border: none !important;
  box-shadow: 0 0 5px 0 #0000004d;
  padding: 5px 8px;
  white-space: wrap;
}

.StructureFlex .arrow_box {
  color: #151515;
  font-size: 12px;
  margin-bottom: 5px;
}

/* .StructureFlex .headspan {
    margin-left: auto;
} */

.StructureFlex .paraspan {
  font-weight: 600;
}

/* .structureBtmPartSection .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.structureBtmPartSection svg {
    height: 520px;
    width: 100%;
}

.structureBtmPartSection foreignObject {
    height: 520px;
    width: 100%;
}

.structureBtmPartSection .apexcharts-canvas {
    height: 100% !important;
}

.structureBtmPartSection #chart>div {
    min-height: 350px !important;
} */

/* Responsive */
@media (max-width: 1499px) {
  .topSkuTenBtns {
    font-size: 13px;
  }

  .structureBtmPartSection svg {
    height: 400px;
  }

  .structureBtmPartSection foreignObject {
    height: 400px;
  }
}

@media (max-width: 1399px) {
  /* .toolInSmallTexts {
        font-size: 12px;
    } */

  /* .toolInSmallDates {
        font-size: 15px;
    } */

  .alltoolTipDivsDiv {
    width: 16%;
  }

  .topSkuTenBtns {
    font-size: 11px;
  }

  .topSkuTenBtns:hover {
    font-size: 10px;
  }

  /* .structureBtmPartSection svg {
        height: 350px;
    }

    .structureBtmPartSection foreignObject {
        height: 350px;
    } */
}

@media (max-width: 1199px) {
  .toolInSmallTexts {
    font-size: 10px;
  }

  .toolInSmallDates {
    font-size: 13px;
  }

  /* .toolTipDiv {
        height: 90px;
    } */

  .structureBtmPartSection svg {
    height: auto;
  }

  .structureBtmPartSection foreignObject {
    height: auto;
  }
}

@media (max-width: 991px) {
  .mainAllToUlDiv {
    overflow-x: auto;
    padding: 15px 12px;
  }

  .mainAllToUlDiv::-webkit-scrollbar {
    display: none;
  }

  .alltoolTipDivsDiv {
    width: 23%;
  }

  .toolInSmallDates {
    font-size: 12px;
  }

  .StructureFlex {
    flex-direction: column;
  }

  .struCOlInDiv {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .strucHead {
    font-size: 15px;
  }

  .snspArtDiv span {
    font-size: 11px;
  }

  .dropDownSnLi label {
    font-size: 11px;
  }

  .dropDownSnLi input {
    width: 11px;
    height: 10px;
  }

  .alltoolTipDivsDiv {
    width: 22%;
  }

  .toolTipDiv {
    height: 75px;
  }
}

@media (max-width: 575px) {
  .topSkuHead {
    font-size: 12px;
  }

  .topSkuTenBtns {
    font-size: 10px;
  }

  .alltoolTipDivsDiv {
    width: 30%;
  }

  .toolTipDiv {
    height: 75px;
  }
}

@media (max-width: 479px) {
  .strucFirstDiv {
    flex-direction: column;
    align-items: center;
  }

  .strucHead {
    margin-bottom: 7px;
  }

  /* .toolTipDiv {
        height: 85px;
    } */

  .topSkuHead {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .topSkusDiv {
    flex-direction: column;
  }

  .alltoolTipDivsDiv {
    width: 47%;
    margin-right: 5px;
  }
}
